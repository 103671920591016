@use "sass:map";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../variables";


@media (max-width: map.get($grid-breakpoints,'lg')){
    header{
        transition: 1s;
    }
    header.background{
        background: $light;
    }

    header nav {
        background: $light;
        width: 0;
        height: 0;
        position: fixed;
        left: 0; top: 0;
        display: flex; flex-direction: column; justify-content: center; align-items: center;
        transition: 0.5s;
        overflow: hidden; opacity: 0.5;

        &.opened {
            width: $vw-full;
            height: 100vh; opacity: 1;
        }

        a{
            margin: 0;
        }
    }
}
