@use "sass:map";

.min-vh-25 {
    min-height: 25vh;
}

.min-vh-30 {
    min-height: 30vh;
}

.min-vh-40 {
    min-height: 40vh;
}

.min-vh-50 {
    min-height: 50vh;
}

.vh-25 {
    height: 25vh;
}

.vh-30 {
    height: 30vh;
}

.vh-40 {
    height: 40vh;
}

.vh-50 {
    height: 50vh;
}

.vh-60 {
    height: 60vh;
}

.vh-70 {
    height: 70vh;
}

//relative
.h-25 {
    height: 25%;
}

.h-30 {
    height: 30%;
}

.h-40 {
    height: 40%;
}

.h-50 {
    height: 50%;
}

.h-60 {
    height: 60%;
}

.h-80 {
    height: 80%;
}

@media (min-width: map.get($grid-breakpoints,'lg')){
    .vh-lg-50 {
        height: 50vh;
    }
}
