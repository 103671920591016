@import "variables.scss";

$size: 7px;
$first: $background;
$secondary: $background;

.basket {
    background: linear-gradient(45deg, $first 12%, transparent 0, transparent 88%, $first 0),
    linear-gradient(135deg, transparent 37%, $secondary 0, $secondary 63%, transparent 0),
    linear-gradient(45deg, transparent 37%, $first 0, $first 63%, transparent 0),
    $light;
    background-size: $size $size;
}


.argyle {
    $deepColor: rgba(0, 0, 0, .1);
    $stripColor: rgba(255, 255, 255, .1);
    $argyleSize: 60px;
    background-color: grey;
    background-image:
        repeating-linear-gradient(120deg,
            $stripColor,
            $stripColor 1px,
            transparent 1px,
            transparent ($argyleSize / 2)
        ),
        repeating-linear-gradient(60deg,
            $stripColor,
            $stripColor 1px,
            transparent 1px,
            transparent ($argyleSize / 2)
        ),
        linear-gradient(60deg,
            $deepColor 25%,
            transparent 25%,
            transparent 75%,
            $deepColor 75%,
            $deepColor
        ),
        linear-gradient(120deg,
            $deepColor 25%,
            transparent 25%,
            transparent 75%,
            $deepColor 75%,
            $deepColor
        );
    //noinspection CssInvalidFunction
    background-size: ($argyleSize / sqrt(3)) $argyleSize;
}

.houndstooth {
    $size: 0.5em;
    //noinspection CssReplaceWithShorthandSafely
    background:
        linear-gradient(-45deg,
            $light-2 25%,
            transparent 25%,
            transparent 75%,
            black 75%,
            black
        ) 0 0,
        linear-gradient(-45deg,
            black 25%,
            transparent 25%,
            transparent 75%,
            $light-2 75%,
            $light-2
        ) ($size / 2) ($size / 2),
        linear-gradient(45deg,
            black 17%,
            transparent 17%,
            transparent 25%,
            black 25%,
            black 36%,
            transparent 36%,
            transparent 64%,
            black 64%,
            black 75%,
            transparent 75%,
            transparent 83%,
            black 83%
        ) ($size / 2) ($size / 2);
    background-color: $light-2;
    background-size: $size $size;
    background-clip: content-box;
}
