@use "sass:map";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$light: #f6f9ff;
$light-2: #c6c3f7;
$background: #d0cde1;
$purple: #6c63ff;
$purple2: #5852b3;
$dark-purple: #3f3d56;

$scrollbarWidth:5;

$vw-full: 100vw;
