@import "~bootstrap/scss/functions";
@import "variables";
$primary: $purple;
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack
@import "patterns";
@import "heights";

@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap');

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $background;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $purple2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $dark-purple;
}

h1{
    font-weight: 900;
}

h2 {
    font-weight: 700;
}

p {
    font-weight: 500;
}

html {
    background: $light; overflow-x: hidden;
}

body {
    font-family: 'Signika', sans-serif;
    background: $light; overflow-x: hidden;
}

* {-webkit-font-smoothing: antialiased;}

a:not(.icon) {
    text-decoration: none;
    color: $purple;
    transition: 0.5s;
    position: relative;

    &::after {
        transition: 0.5s;
        content: "";
        width: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: $purple;
    }

    &:hover {
        color: $dark-purple;

        &::after{
            width: 100%;
            background-color: $dark-purple;
        }
    }
}

a.icon {
    text-decoration: none;
    color: $dark-purple;

}

nav {
    height: 60px; line-height: 60px;

    a {
        font-size: 24px;
        margin-right: 1.5rem;
        font-weight:700
    }
}

.center-both {
    position: absolute; left: 50%; top: 50%;
    transform: translate(-50%,-50%);
}

.hor {
    position: absolute; left: 50%; top: 0; transform: translateX(-50%);
}

.vw-100{
    width: $vw-full !important;
}
.rec-20 {
    width: 20px; height: 20px;
}
.rec-25 {
    width: 25px; height: 25px;
}
.rec-30 {
    width: 30px; height: 30px;
}
.rec-50 {
    width: 50px; height: 50px;
}
.rec-75 {
    width: 75px; height: 75px;
}
.rec-100 {
    width: 100px; height: 100px;
}

.fit-img{
    min-height: 0;
    min-width: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

img {
    user-select: none; -webkit-user-drag: none; -webkit-user-select: none;
}

header {
    position: fixed; top: 0; left: 0; width: $vw-full; z-index: 100;
}

section {
    position: relative; background: rgba(255,255,255,0.5); padding-top: 90px;
}

.full-screen {
    position: fixed; top: 0; left: 0; width: $vw-full; height: 100vh;
}

.work-card {
    box-shadow: 0 0 10px #444;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer;
    background-color: #fff;

    .ribbon {
        background: #3f3d56;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        color: #f6f9ff;
        box-shadow: 0 0 15px #444;
        transform-origin: 0 0;
        transform:translateX(50%) rotate(45deg) translateX(-15%);
        padding: 0 1rem;
        min-width: 40%;
        line-height: normal;
        height: 1.5rem;
    }

    .card-img-top {
        img {
            width: 100%;
        }
    }
}

#mobile-menu-bars {
    font-size: 36px;
    position: relative; z-index: 1; color: $purple;
    cursor: pointer; transition: 0.2s;
}

#logo-container {
    position: relative; z-index: 1; color: $purple; top: 0; right: 0;
    max-width: 100px;
}

@import "media/app";
